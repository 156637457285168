import { userAxios } from '../index'

const regionList = async (perPage) => {
    try {
        return await userAxios.get(`regions/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const regionCreate = async (data) => {
    try {
        return await userAxios.post('regions', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const regionUpdate = async (id, data) => {
    try {
        return await userAxios.put(`regions/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const regionDetail = async (id) => {
    try {
        return await userAxios.get(`regions/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const regionDelete = async (id) => {
    try {
        return await userAxios.delete(`regions/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const regionPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`regions/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const regionFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`region/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const regionFilterPagination = async (perPage, page, data) => {
    try {
        return await userAxios.post(`region/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const regionTeamAdd = async (data) => {
    try {
        return await userAxios.post(`region/team/add_user`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const regionTeamChange = async (data) => {
    try {
        return await userAxios.post(`region/team/move_user`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const regionTeamDelete = async (data) => {
    try {
        return await userAxios.post(`region/team/quit_user`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const regionAddFacility = async (id, data) => {
    try {
        return await userAxios.put(`region/facilities/add/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const regionFacilityChange = async (id, data) => {
    try {
        return await userAxios.put(`region/facilities/move/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const regionFacilityDelete = async (id) => {
    try {
        return await userAxios.put(`region/facilities/quit/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    regionList,
    regionCreate,
    regionUpdate,
    regionDetail,
    regionDelete,
    regionPagination,
    regionFilter,
    regionFilterPagination,
    regionTeamAdd,
    regionTeamChange,
    regionTeamDelete,
    regionAddFacility,
    regionFacilityChange,
    regionFacilityDelete
}