<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="facilityData.avatar"
          :text="avatarText(facilityData.name)"
          variant="dark"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ facilityData.name }}-{{ facilityData.cities.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          />
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-danger" class="ml-1">
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Name')" label-for="name">
            <b-form-input id="name" v-model="facilityData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input
              id="email"
              v-model="facilityData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- address -->
        <b-col cols="12" md="4">
          <b-form-group label="Address" label-for="address">
            <b-form-input id="address" v-model="facilityData.adress" />
          </b-form-group>
        </b-col>

        <!-- Phone -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Phone')" label-for="phone">
            <b-form-input id="phone" v-model="facilityData.phone" />
          </b-form-group>
        </b-col>

        <!-- Pbx -->
        <b-col cols="12" md="4">
          <b-form-group label="Pbx" label-for="pbx">
            <b-form-input id="pbx" v-model="facilityData.pbx" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.City')" label-for="city">
            <b-form-input id="city" v-model="facilityData.cities.name" />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex mt-2">
        <feather-icon icon="MapPinIcon" size="19" />
        <h4 class="mb-0 ml-50">Info</h4>
      </div>
      <b-row class="mt-1">
        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Status')" label-for="user-status">
            <v-select
              v-model="facilityData.status"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group
            label="Attention Schedule Mon-Sat"
            label-for="attention"
          >
            <div class="d-flex align-items-center">
              <flat-pickr
                v-model="attention_schedule.mon_to_sat.from"
                :config="configAttentionSchedule"
                class="form-control"
                placeholder="Monday-Saturday"
              />
              <span class="p-1">to</span>
              <flat-pickr
                v-model="attention_schedule.mon_to_sat.to"
                :config="configAttentionSchedule"
                class="form-control"
                placeholder="Monday-Saturday"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Attention Schedule Sun" label-for="attention">
            <div class="d-flex align-items-center">
              <flat-pickr
                v-model="attention_schedule.sunday.from"
                :config="configAttentionSchedule"
                class="form-control"
                placeholder="Sunday"
              />
              <span class="p-1">to</span>
              <flat-pickr
                v-model="attention_schedule.sunday.to"
                :config="configAttentionSchedule"
                class="form-control"
                placeholder="Sunday"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Date of Creation" label-for="creation">
            <flat-pickr
              id="creation"
              v-model="facilityData.creation_date"
              :config="config"
              class="form-control"
              placeholder="MM-DD-YYYY"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="saveChanges"
    >
      Save Changes
    </b-button>
    <b-button variant="outline-secondary" type="reset"> Reset </b-button>

    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { computed, ref } from "@vue/composition-api";

import axiosF from "@/services/admin/facilities";

import flatPickr from "vue-flatpickr-component";
import ToastNotification from "@/components/ToastNotification";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    flatPickr,
    ToastNotification,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    facilityData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { refs }) {
    const statusOptions = [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ];

    const horario = props.facilityData.attention_schedule;
    const horarioFrom = horario.split(",");
    const attention_schedule = {
      mon_to_sat: {
        from: null,
        to: null,
      },
      sunday: {
        from: null,
        to: null,
      },
    };
    const config = {
      altInput: true,
      altFormat: "m-d-Y",
      dateFormat: "m-d-Y",
      allowInput: true,
    };

    const objectohorario = horarioFrom.map((item) => {
      if (item.split('"')[1] == "mon_to_sat") {
        attention_schedule.mon_to_sat.from = item.split('"')[3].split("-")[0];
        attention_schedule.mon_to_sat.to = item.split('"')[3].split("-")[1];
      }
      attention_schedule.sunday.from = item.split('"')[3].split("-")[0];
      attention_schedule.sunday.to = item.split('"')[3].split("-")[1];
    });

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.facilityData.avatar = base64;
      }
    );

    const saveChanges = () => {
      const attention_scheduleV2 = {
        mon_to_sat: `${attention_schedule.mon_to_sat.from}-${attention_schedule.mon_to_sat.to}`,
        sunday: `${attention_schedule.sunday.from}-${attention_schedule.sunday.to}`,
      };
      props.facilityData.attention_schedule = attention_scheduleV2;
      axiosF
        .facilityUpdate(props.facilityData.id, props.facilityData)
        .then(() => {
          refs.toast.success("Facility updated successfully");
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          refs.toast.error("Error updating facility");
        });
    };
    const configAttentionSchedule = {
      enableTime: true,
      noCalendar: true,
      enableSeconds: true,
      dateFormat: "H:i:S",
    };

    return {
      avatarText,
      statusOptions,
      configAttentionSchedule,
      config,
      attention_schedule,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      saveChanges,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
